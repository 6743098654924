<template>
  <dashboard-page-wrapper title="Templates">
    <template-menu activeMenu="Hiring Manager Templates"/>
    <b-row>
      <b-col md="12">
        <card bodyClasses="p-3 pb-3">
          <div class="d-flex align-items-center">
            <base-button type="primary"
            :disabled="hmTemplates.length >= availableTemplates"
            @click="createTemplate"><i class="ni ni-fat-add"></i>New template</base-button>
            <div class="ml-3"><b>{{hmTemplates.length}}/{{availableTemplates}}</b></div>
          </div>
          <b-row class="mt-3">
            <b-col md="5">
              <templates-list
              :templates="hmTemplates"
              v-model="selectedTemplate"
              :formBlocked="formBlocked"
              :unsavedFormData="unsavedFormData"
              @save-template-confirmation="saveChangesConfirmation"
              :isHiringManagerTemplate="true"
              />
            </b-col>
            <b-col md="7">
              <template-form
              :template="selectedTemplate"
              :canCreate="canCreate"
              @form-block-change="formBlockChangeHandler"
              @update-template="updateTemplate"
              :isHiringManagerTemplate="true"
              />
            </b-col>
          </b-row>
        </card>
      </b-col>
    </b-row>
    <confirmation-modal
    ref="saveHiringManagerChangesModal"
    header="Save changes"
    question="You have made changes to this template, save changes before you leave?"
    cancelBtnText="Cancel"
    confirmBtnText="Save"
    @on-accept="saveTemplateChanges"
    @on-reject="changeTemplate"
  />
  </dashboard-page-wrapper>
</template>
<script>
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import TemplateForm from './TemplateForm.vue';
import TemplateMenu from './TemplateHeaderMenu.vue';
import TemplatesList from './TemplatesList.vue';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import {mapActions, mapGetters} from 'vuex';
import {
  CREATE_HM_TEMPLATE_REQUEST,
  FETCH_HM_TEMPLATES_REQUEST,
  UPDATE_HM_TEMPLATE_REQUEST,
} from '@/store/storeActions';

export default {
  components: {
    DashboardPageWrapper,
    TemplateForm,
    TemplatesList,
    TemplateMenu,
    ConfirmationModal,
  },
  data: function() {
    return {
      selectedTemplate: null,
      formBlocked: false,
      unsavedFormData: null,
    };
  },
  computed: {
    ...mapGetters([
      'hmTemplates',
      'hmTemplatesLoaded',
      'totalTeamTemplates',
    ]),
    availableTemplates() {
      return this.totalTeamTemplates || 15;
    },
    canCreate() {
      return this.hmTemplates.length < this.availableTemplates;
    },
  },
  watch: {
    hmTemplates: {
      handler: function(value) {
        this.selectedTemplate = (!!value.length) ?
        (!!this.selectedTemplate) ?
        (value.find((template) => template.id === this.selectedTemplate.id)) : value[0] : null;
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.hmTemplatesLoaded) {
      this.FETCH_HM_TEMPLATES_REQUEST();
    }
  },
  methods: {
    ...mapActions([
      FETCH_HM_TEMPLATES_REQUEST,
      CREATE_HM_TEMPLATE_REQUEST,
      UPDATE_HM_TEMPLATE_REQUEST,
    ]),
    createTemplate: async function() {
      this.formBlocked && this.saveChangesConfirmation({data: {...this.unsavedFormData}});
      const payload = {
        name: 'Example: Intro',
        // eslint-disable-next-line
        message: 'Hey [FirstName], you have some great experience and I would love to speak with you about your recent job application with us. How does your schedule look?',
      };
      this.canCreate && await this.CREATE_HM_TEMPLATE_REQUEST({payload});
      this.selectedTemplate = null;
    },
    saveChangesConfirmation: function(data) {
      this.$refs.saveHiringManagerChangesModal.openModal(data);
    },
    formBlockChangeHandler: function({blocked, unsavedFormData}) {
      this.formBlocked = blocked;
      this.unsavedFormData = unsavedFormData;
    },
    saveTemplateChanges: function({data, template}) {
      (!!template) && (this.selectedTemplate = template);
      (!!data && !!data.id) && this.updateTemplate({payload: {name: data.name, message: data.message}, id: data.id});
    },
    updateTemplate: function({payload, id}) {
      this.UPDATE_HM_TEMPLATE_REQUEST({payload, id});
      this.formBlocked = false;
    },
    changeTemplate: function({template}) {
      this.selectedTemplate = template;
    },
  },
};
</script>
